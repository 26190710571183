import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PageWithHeader from '../components/pageWithHeader';
import style from './trends.module.scss'

const Trends = ({ data }) => {
  const trends = data.trends.edges.map(i => i.node);

  return (<PageWithHeader title={'Trends'}>
    <article className={style.article}>
      {trends.map(t => (
        <a className={style.card} href={t.url} target="_blank" rel="noopener noreferrer">
          <Img fluid={t.localImage.childImageSharp.fluid} />
          <div className={style.title}>{t.title}</div>
        </a>
      ))}

    </article>
  </PageWithHeader>)
}

export default Trends;

export const query = graphql`
  query TrendsQuery{
    trends: allContentfulTrend(sort: {order: DESC, fields: date}) {
      edges {
        node {
          title
          url
          imageUrl
          date
          localImage {
            url
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 200, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
